import Emitter from '../../core/Emitter'
import EventBus from '../../core/EventBus'
import {
    getScrollTop as getScrollTopNative,
    getScroll as getScrollNative,
    enableScrolling as enableScrollingNative,
    disableScrolling as disableScrollingNative,
} from '../Viewport/scroll'
import scrollToNative from '../Viewport/scrollTo'
import throttle from 'lodash/throttle'
import Scrollbar from 'smooth-scrollbar'
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll'
import TouchMouseInputResolver from '../../meta/TouchMouseInputResolver'

// Scrollbar.use(OverscrollPlugin)

import { default as scrollToElementNative } from '../Viewport/scrollToElement'

class Scroll extends Emitter {
    constructor(context = window) {
        super()

        this.context = context
        this.current = {
            x: 0,
            y: 0,
        }
        this.raf = null
        this.container = document.querySelector('.scroll-Container')

        this.handleResize = throttle(this.handleResize, 50)

        window.addEventListener('resize', this.handleResize)
        window.addEventListener('scroll', this.handleNativeScroll)
        EventBus.on('contentchange', this.handleResize)

        this.scrollbar = null
        this.isVirtual = false

        this.staticLimit = {
            x: this.container.offsetWidth - window.innerWidth,
            y: this.container.offsetHeight - window.innerHeight,
        }
    }

    getScroll() {
        if (this.isVirtual) {
            return {
                x: this.scrollbar.scrollLeft,
                y: this.scrollbar.scrollTop,
            }
        } else {
            return getScrollNative()
        }
    }

    handleNativeScroll = () => {
        if (this.isVirtual) {
            return
        }

        this.current = getScrollNative()

        this.render()
    }

    handleResize = () => {
        this.resize()
    }

    handleVirtualScroll = (status) => {
        this.current = {
            x: status.offset.x,
            y: status.offset.y,
        }

        this.render()
    }

    render = () => {
        this.emit('scroll', {
            offset: { ...this.current },
        })
    }

    resize = () => {
        if (!this.isVirtual) {
            this.staticLimit = {
                x: this.container.offsetWidth - window.innerWidth,
                y: this.container.offsetHeight - window.innerHeight,
            }
        }
        this.emit('resize')
    }

    setPosition(x, y) {
        y = y === 0 ? -80 : y //ios fix, overscroll nulovou pozici kvuli liste
        if (this.isVirtual) {
            this.scrollbar.setPosition(x, y)
        } else {
            window.scrollTo(x, y)
        }
    }

    useNative(hash = null) {
        this.isVirtual = false
        this.container.classList.remove('is-virtual')

        if (hash) {
            this.scrollToAnchor(hash)
        }
    }

    useVirtual(hash = null) {
        this.isVirtual = true
        this.container.classList.add('is-virtual')

        if (!this.scrollbar) {
            this.scrollbar = Scrollbar.init(this.container, {
                renderByPixels: false,
            })
            this.scrollbar.addListener(this.handleVirtualScroll)
        }

        if (hash) {
            this.scrollToAnchor(hash)
        }
    }

    getLimit() {
        if (this.isVirtual && this.scrollbar) {
            return this.scrollbar.limit
        } else {
            return this.staticLimit
        }
    }

    scrollToAnchor(hash) {
        const target = document.getElementById(hash.replace('#', ''))

        if (target) {
            setTimeout(() => {
                scrollToElement(target, {
                    animate: false,
                })
            }, 1)
        }
    }
}

const scrollInstance = new Scroll()

const _defaults = {
    offset: 80,
    animate: true,
}

export function scrollToElement(element, options = {}) {
    options = {
        ..._defaults,
        ...options,
    }

    if (scrollInstance.isVirtual) {
        if (options.animate) {
            scrollInstance.scrollbar.scrollIntoView(element, {
                offsetTop: options.offset ? options.offset : 0,
                onlyScrollIfNeeded: options.loose,
                alignToTop: true,
            })
        } else {
            const box = element.getBoundingClientRect()
            scrollInstance.scrollbar.setPosition(0, box.top - options.offset)
        }
    } else {
        scrollToElementNative(element, options)
    }
}

export function scrollTo(y) {
    if (scrollInstance.isVirtual) {
        scrollInstance.scrollbar.scrollTo(0, y, 600)
    } else {
        scrollToNative(y)
    }
}

export function getScroll() {
    if (scrollInstance.isVirtual) {
        return scrollInstance.scrollbar.offset
    } else {
        return getScrollNative()
    }
}

export function getScrollTop() {
    if (scrollInstance.isVirtual) {
        return scrollInstance.scrollbar.scrollTop
    } else {
        return getScrollTopNative()
    }
}

export function disableScrolling() {
    disableScrollingNative()
}

export function enableScrolling() {
    enableScrollingNative()
}

window.scrollInstance = scrollInstance

export default scrollInstance
