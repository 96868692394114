// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import './core/Sentry'

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import './polyfills/SVGElement.classList'
import './polyfills/SVGElement.dataset'
import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'
import enquire from 'enquire.js'
import { queries } from './core/config'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'
import './plugins/giant.trigger'
import './plugins/giant.autosubmit'

// nette
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

window.lazySizesConfig.lazyClass = 'is-lazy'
window.lazySizesConfig.loadingClass = 'is-lazy-loading'
window.lazySizesConfig.loadedClass = 'is-lazy-loaded'
import 'lazysizes'

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import ScrollService, { scrollTo } from './services/Scroll'
import { USE_NATIVE_SCROLL, IE } from './core/config'

// prevent scroll on anchor
// if (window.location.hash) {
//     setTimeout(() => window.scrollTo(0, 0), 0)
// }

if (USE_NATIVE_SCROLL) {
    ScrollService.useNative(window.location.hash)
} else {
    ScrollService.useVirtual(window.location.hash)
}

import { loadComponents, removeComponents } from './core/componentUtils'
loadComponents().then(() => {
    const id = window.location.hash.replace('#', '')
    const anchor = document.getElementById(id)
    if (anchor) {
        setTimeout(() => {
            let offset = anchor.matches('.Section:not(.Section--appended):not(.Section--bare)') ? 0 : 80
            offset = anchor.getBoundingClientRect().top + ScrollService.getScroll().y - offset
            ScrollService.setPosition(0, offset)
        }, 100)
    }
})

import Swup from 'swup'
import swupGtmPlugin from 'swup/plugins/swupGtmPlugin'

/**
 * Swup stuff
 */

const scrollStack = []

const pushScrollPosition = () => {
    const location = window.location.href
    const offset = ScrollService.getScroll().y

    scrollStack.push({
        location,
        offset,
    })

    if (scrollStack > 10) {
        scrollStack.shift()
    }
}

const popScrollPosition = () => scrollStack.pop()

let currentScroll = null

if (!IE) {
    let skipPopState = false
    EventBus.on('swup:custom:block', () => (skipPopState = true))

    const DESKTOP_ANIMATION_SELECTOR = '.store-Group-title span, .store-List-item, .article-Card--huge, .article-List-item'
    const MOBILE_ANIMATION_SELECTOR = '.article-Card--huge, .article-List-item'
    const ELEMENTS = ['#swup', '#nav', '#modals', '#mobile-nav-content', document.getElementById('newsletter-spacer') ? '#newsletter-spacer' : null, document.getElementById('languages') ? '#languages' : null].filter(Boolean)

    const swup = new Swup({
        LINK_SELECTOR: ['a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([data-component]):not([target="_blank"])', 'a[href^="/"]:not([href^="/data"]):not([data-no-swup]):not([data-rel="news-modal"]):not([data-rel="job-modal"]):not([data-component]):not([target="_blank"])', 'a[href^="#"]:not([data-no-swup]):not([data-component])'].join(', '),
        elements: ELEMENTS,
        debugMode: false,
        doScrollingRightAway: false,
        animationSelector: DESKTOP_ANIMATION_SELECTOR,
        animateScroll: false,
        preload: false,
        cache: true,
        skipPopStateHandling: (event) => {
            if (skipPopState) {
                skipPopState = false
                return true
            }

            if (event?.state?.source === 'swup') {
                return false
            }

            return true
        },
        plugins: [swupGtmPlugin],
    })

    enquire.register(queries.smallWideMax, {
        match: () => {
            swup.options.animationSelector = MOBILE_ANIMATION_SELECTOR
        },
        unmatch: () => {
            swup.options.animationSelector = DESKTOP_ANIMATION_SELECTOR
        },
    })

    swup.on('clickLink', (event) => {
        if (event.target.href === window.location.href) {
            if (!event.defaultPrevented) {
                scrollTo(0)
            }
        } else {
            pushScrollPosition()
            EventBus.emit('swup:clicklink', { target: event.target })
        }
    })

    // scroll
    swup.on('willReplaceContent', (event) => {
        queryAll('[data-swup]').forEach((element) => {
            removeComponents(element)
        })
    })

    swup.on('contentReplaced', (event) => {
        Nette.reinit()

        const id = window.location.hash.replace('#', '')
        const anchor = document.getElementById(id)

        let footerInputError = document.querySelector('#footerNewsletter .InputError')
        let footerInput = document.querySelector('#footerNewsletter input')

        if(footerInputError) {
            footerInputError.textContent = ''
        }

        if(footerInput) {
            footerInput.value = ''
        }

        if (currentScroll) {
            requestAnimationFrame(() =>{
                requestAnimationFrame(() => {
                    ScrollService.setPosition(0, currentScroll.offset)
                    currentScroll = null
                })
            })
        } else if (anchor) {
            requestAnimationFrame(() =>{
                requestAnimationFrame(() => {
                    const box = anchor.getBoundingClientRect()
                    ScrollService.setPosition(0, box.top + ScrollService.getScroll().y)
                })
            })
        } else if (swup.transition.custom !== 'category-list') {
            requestAnimationFrame(() =>{
                requestAnimationFrame(() =>{
                    ScrollService.setPosition(0, 0)
                })
            })
        }

        queryAll('[data-swup]').forEach((element) => {
            loadComponents(element)
        })

        if ($.nette) {
            $.nette.load()
        }

        EventBus.emit('swup:contentreplaced')
        setTimeout(() => ScrollService.resize(), 0)
    })

    swup.on('popState', () => {
        const settings = popScrollPosition()
        if (settings?.location == window.location.href) {
            currentScroll = settings
        }
    })
}

try {
    Object.assign({})
} catch (e) {}
